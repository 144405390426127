// style
require('normalize.css/normalize.css');
require('./css/_index.css');

// script
const jQuery = require('jquery/dist/jquery');
window.jQuery = jQuery;
window.$ = jQuery;

require('./js/apple-weblit-marker');

window.FontAwesomeSvgCore = require('@fortawesome/fontawesome-svg-core');

// lib
window.Cookies = require('js-cookie');
window.ClipboardJS = require('clipboard/dist/clipboard');
// font-awesome

// require('@popperjs/core/dist/umd/popper');
window.tippy = require('tippy.js/dist/tippy.umd');
require('tippy.js/dist/tippy.css');
require('tippy.js/themes/light.css');

require('mark.js/dist/jquery.mark');

require('jquery-modal/jquery.modal');
require('jquery-modal/jquery.modal.css');

// lightbox
require('../lightbox/css/lightbox.css');
require('../lightbox/js/lightbox');

// iframe resizer
require('iframe-resizer');

//diff-match-patch
const diff_match_patch = require('diff-match-patch');
window.diff_match_patch = diff_match_patch;
window.DIFF_DELETE = diff_match_patch.DIFF_DELETE;
window.DIFF_INSERT = diff_match_patch.DIFF_INSERT;
window.DIFF_EQUAL = diff_match_patch.DIFF_EQUAL;

// local js
import {BootLazy} from "./js/boot-lazy";
window.bootLazy = new BootLazy();

require('./js/disable-submit');

require('./js/jquery.accordion');
require('./js/jquery.anchor-link');
require('./js/jquery.closed-on-narrow-screen');
require('./js/jquery.embed');
require('./js/jquery.fold');
require('./js/jquery.font-awesome-loader');
require('./js/jquery.lazy-dom');
require('./js/jquery.loadpanel');
require('./js/jquery.moved');
require('./js/jquery.navfold');
require('./js/jquery.newmarker');
require('./js/jquery.ntbr');
require('./js/jquery.notification-bar');
require('./js/jquery.pageview');
require('./js/jquery.popular');
// require('./js/jquery.prevent-repeated-submission');
require('./js/jquery.pukiwiki');
require('./js/jquery.randommes');
require('./js/jquery.realtime-countdown');
require('./js/jquery.realtime-date');
require('./js/jquery.recentdetail');
require('./js/jquery.ref-ext-image-fix');
require('./js/jquery.responsive-navigation');
require('./js/jquery.search-word-highlight');
require('./js/jquery.browsing-statistics');
require('./js/jquery.usage-statistics');
require('./js/jquery.zcomment-resize');
require('./js/jquery.share-panel');
require('./js/jquery.admin-contact');

window._plugin_listbox3_changeMode = require('./js/listbox3');

import './js/fonts'
